const LOGIN_SETTING = 'login/LOGIN_SETTING' as const;

type LOGINAction =
  | ReturnType<typeof loginSetting>
;

export const loginSetting = (diff: {login: boolean, level?: number, name?: string, nickname?: string, userid?: string, _id?: string}) => ({
  type: LOGIN_SETTING,
  payload: diff
});

export type LOGINState = {
  login: boolean;
  level?: number,
  name?: string,
  nickname?: string,
  userid?: string,
  _id?: string,
};

const initialState: LOGINState = {
  login: false,
  level: 0,
  name: "",
  nickname: "",
  userid: "",
  _id: "",
};

function login(
  state: LOGINState = initialState,
  action: LOGINAction
): LOGINState {
  switch (action.type) {
    case LOGIN_SETTING:
      return { ...state, login: action.payload.login, level: action.payload.level, name: action.payload.name, nickname: action.payload.nickname, userid: action.payload.userid, _id: action.payload._id };
    default:
      return state;
  }
}

export default login;