import { useState, useEffect } from "react";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import { useTranslation } from 'react-i18next';

import { BoardListProps } from "./BoardList";

import { RootState, PathState } from "@store/index";
import { dateToString } from '@shared/index';

import './boardList.scss';

const FreeboardList = ({ data, detail, background, id }: BoardListProps) => {

  const { t, i18n } = useTranslation();

const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  const [newList, setNewLIst] = useState(false);

  const NewList = () => {
    var now = new Date();
    var yesterday = new Date(now.setDate(now.getDate() - 1));
    const date: Date = new Date(data.regdate);
    setNewLIst(yesterday.getTime() < date.getTime());
  }

  useEffect(() => {
    if (!detail) {
      NewList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.notice, detail: detail })}
      onClick={() => {
        navigate(`/community/${path.nowPath}/detail/${data._id}`, {state: {_id: data._id}});
      }}
      style={{ "background": background }}
    >
      <p className="id">{id}</p>
      {data.fixed && (
        <p className="category">
          <span className={data.category}>{data.category}</span>
        </p>
      )}
      <div className="title_box">
        <p className="title">
          {data.title}
        </p>
        {newList && <span className="new"></span>}
      </div>
      
      {(data.userid !== undefined || data.userid !== null) && <p className="user">{data.notice ? "관리자" : data.userid?.nickname}</p>}
      
      <p className="date">
        {dateToString(data.regdate, 'yyyy.MM.dd', i18n.language)}
      </p>
      
      <p className="comment">{data.comment === null || data.comment === undefined ? "0" : data.comment.length}</p>
      {(data.hart !== undefined || data.hart !== null) && (
        <div className="like">
        <p>{data.hart?.length}</p>
      </div>
      )}
      <p className="report">{data.report}</p>
      <p className="view">{data.hit}</p>
    </div>
  );
}

export default FreeboardList;