import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LOGINState, RootState, loginSetting } from "@store/index";
import { MenuBox } from '@components/index';
import { zemiMenu, useRouting } from '@shared/config/router/path';
import { ReactComponent as ExternalLinkIcon } from '@assets/images/layer/external-link.svg';
import './mainHeader.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const MainHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goToMain } = useRouting();
  const currentCategory = useSelector((state: RootState) => state.gnb.currentCategory);
  const login: LOGINState = useSelector((state: RootState) => state.login);

  const [loginMessage, setLoginMessage] = useState("로그인");

  useEffect(() => {
    console.log(login.login);
    console.log(sessionStorage.getItem("accessToken"));
    if (login.login && sessionStorage.getItem("accessToken")) {
      setLoginMessage("로그아웃");
    } else {
      setLoginMessage("로그인");
    }
  }, [login]);

  return (
    <div className="main_header">
      <div className="main_header_left">
        <p className="logo"
          onClick={() => {
            navigate(`/board/notice`);
          }}></p>
        <p className="main_header_title">Administrator</p>
      </div>
      <div className="main_header_menu">
        {zemiMenu.map((d: any) => (
          <MenuBox children={d.children} key={d.id} id={d.id} title={d.title} link={d.link} currentPath={location} currentCategory={currentCategory} />
        ))}
        <div className='sub_menu_back'></div>
      </div>
      <div className="main_header_right">
        <div className="move_button"
          onClick={() => {
            window.open(`https://zemiverse.com`, `_blank`);
          }}
        >
          <p>ZEMI</p>
          <ExternalLinkIcon />
        </div>
        <p
          onClick={() => {
            console.log(login.login);
            if (login.login) {
              sessionStorage.clear();
              dispatch(loginSetting({login: false, level: 0, name: "", nickname: "", userid: "", _id: ""}));
            } else {
              navigate(`/login`);
            }
          }}
        >{loginMessage}</p>
      </div>
    </div>
  )
}
export default MainHeader;