
import { PageTop, BoardList, SelectBox, NormalButton } from '@components/index';
import { boardTitle, REACT_APP_SERVER_URL } from '@shared/utils';
import { useSelector, useDispatch } from "react-redux";
import useSelect from '@hooks/useSelect';
import { useLocation, useNavigate } from 'react-router-dom';

import { RootState, PathState, ModalState, modalSetting, modalReportSetting } from "@store/index";

const UserDetail = () => {
  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  const CategoryList = [
    {id : 1, title : "활성화"},
    {id : 2, title : "접근차단"},
    {id : 3, title : "회원탈퇴"},
  ];
  const selectData = useSelect(CategoryList[0]);

  return(
    <div className="detail list">
      <div className="detail__inner">
        {/* page title */}
        <PageTop title={boardTitle(path.nowPath)} />

        {/* user table */}
        <table className='table__user'>
          <tr>
            <th rowSpan={4}>프로필 사진</th>
            <td rowSpan={4}>
              {/* <div className="image">
                image
              </div> */}
              <img className="image" src="" alt="Profile" />
              
            </td>
            <th>E-mail</th>
            <td>ciela0426@naver.com</td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td>ciela</td>
          </tr>
          <tr>
            <th>소셜(가입일)</th>
            <td>
              <div className="social">
                <p>Facebook</p> 
              </div>
              2022.11.20 18:20:45
            </td>
          </tr>
          <tr>
            <th>계정 연동</th>
            <td>
              <div className="sync">
                <p>연동</p> 
              </div>
              2022.11.20 18:20:45
            </td>
          </tr>
          <tr>
            <th>최종 접속</th>
            <td>2023.05.17</td>
            <th>접근 차단</th>
            <td>2033.10.10 16:12:45</td>
          </tr>
          <tr>
            <th>회원탈퇴</th>
            <td>
              <div className="withdrawal">
                <p>탈퇴</p>
              </div>
              2022.10.12 16:45:12
            </td>
            <th>상태</th>
            <td>
              <SelectBox 
              name="category"
              data={CategoryList}
              selectData={selectData}
              classification />
            </td>
          </tr>
        </table>
        {/* detail bottom */}
        <div className="detail__inner__bottom">
            <NormalButton
              title="목록"
              buttonStyle='box'
              size='md'
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
            <NormalButton
              title="저장"
              buttonStyle='box'
              size='md'
              hierarchy="primary_line"
              onClick={() => {
              }}
            />
        </div>
      </div>
    </div>
  );
}

export default UserDetail;