import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { path } from '@shared/config/router/path';
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { loginSetting, pathSetting, RootState, LOGINState } from "@store/index";

function getLastPath(url : string) {
  var splitUrl : string[] = [];
  splitUrl = url.split('/');
  return splitUrl[2];
}

const App = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login: LOGINState = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      dispatch(loginSetting({login: true}));
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (login.login || sessionStorage.getItem("accessToken")) {
      navigate(`/board/notice`);
    } else {
      navigate(`/login`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let element = useRoutes(path);
  return element;
}
export default App;