import { useState, useMemo } from 'react';

const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);
  const [logMessage, setLogMessage] = useState('');
  const [logType, setLogType] = useState('');

  const _handleChange = (event: any) => {
    setValue(event.target.value);
  }

  const _checkLogType = () => {
    // 엑시오스로 던져!!1
    if(value) {
      setLogMessage("입력된 닉네임은 사용중입니다.");
      setLogType("warn");
      // setLogMessage("입력된 닉네임은 사용불가 합니다.");
      // setLogType("error");
      // setLogMessage("입력된 닉네임은 사용가능 합니다.");
      // setLogType("info");
    } else {
      setLogMessage("");
      setLogType("");
    }
  }

  useMemo(_checkLogType, [value]);

  return {
    value, 
    logMessage,
    logType,
    onChange:_handleChange,
  };
};

export default useInput;