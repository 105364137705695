const NoList = () => {
  return (
    <div className="no_list">
      <div className="img"></div>
      <p className="title">등록된 게시물이 없습니다.</p>
      <p className="description">아직 컨텐츠가 없습니다.</p>
    </div>
  );
}

export default NoList;