import React from 'react';
import ReactDOM from 'react-dom/client';
// pages
import App from './App';
// router
import { BrowserRouter } from 'react-router-dom';
//seo 
import { HelmetProvider } from 'react-helmet-async';
// redux
import rootReducer from './store/index';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
// css
import '@assets/css/reset.css';
import '@assets/css/board.scss';
import '@assets/css/detail.scss';

const store = createStore(rootReducer)
// const persistor = persistStore(store);

const element = (
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const root = ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, element);
root.render(element);