import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import NormalButton from "@components/common/NormalButton/NormalButton";
import { BoardListProps } from "./BoardList";

import { RootState, PathState } from "@store/index";

import './boardList.scss';

const ManagerList = ({ data, detail, background }: BoardListProps) => {

  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
      onClick={() => {
      }}
      style={{ "background": background }}
    >
      <p className="id">{data.id}</p>
      <p className="userId">{data.user}</p>
      <p className="manager">{data.manager}</p>
      <p className="date">{data.date}</p>
      <div className="buttons_box">
        <NormalButton
          title="수정"
          size="xs"
          buttonStyle="box"
          hierarchy="primary_line"
        />
        <NormalButton
          title="수정"
          size="xs"
          buttonStyle="box"
          hierarchy="secondary_gray"
        />
      </div>
    </div>
  );
}

export default ManagerList;