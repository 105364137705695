import { NotFound } from "@components/index";
import MainLayout from "@components/layout/MainLayout/MainLayout";
import { Board, DashBoard, GuideManagement, StudioExamination, StudioManagement, FAQ, Login, Detail, Write, UserDetail } from "@pages/index";
import { RouteObject, useNavigate } from "react-router-dom";

const path: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      { path: "", element: <DashBoard /> },
      {
        path: "studio", children: [
          { path: "content/management", element: <StudioManagement /> },
          { path: "content/examination", element: <StudioExamination /> },
          { path: "guide/management", element: <GuideManagement /> }
        ]
      },
      {
        path: "board", children: [
          { path: "notice", element: <Board /> },
          { path: "notice/*", element: <Detail /> },
          { path: "notice/write", element: <Write /> },
          { path: "update", element: <Board /> },
          { path: "update/*", element: <Detail /> },
          { path: "update/write", element: <Write /> },
          { path: "event", element: <Board /> },
          { path: "event/*", element: <Detail /> },
          { path: "event/write", element: <Write /> },
        ]
      },
      {
        path: "community", children: [
          { path: "free-board", element: <Board /> },
          { path: "free-board/*", element: <Detail /> },
          { path: "free-board/write", element: <Write /> },
          { path: "tip-board", element: <Board /> },
          { path: "tip-board/*", element: <Detail /> },
          { path: "tip-board/write", element: <Write /> },
          { path: "screenshot-board", element: <Board /> },
          { path: "screenshot-board/*", element: <Detail /> },
          { path: "screenshot-board/write", element: <Write /> },
          { path: "report", element: <Board /> },
          { path: "report/*", element: <Detail /> }
        ]
      },
      // {
      //   path: "customer", children: [
      //     { path: "faq", element: <FAQ /> },
      //     { path: "faq/write", element: <Write /> },
      //     { path: "inquiry", element: <Board /> },
      //     { path: "inquiry/*", element: <Detail /> },
      //     { path: "inquiry/*", element: <Detail /> },
      //   ]
      // },
      {
        path: "user", children: [
          { path: "list", element: <Board /> },
          { path: "list/*", element: <UserDetail /> }
        ]
      },
      // {
      //   path: "admin", children: [
      //     { path: "account", element: <Board /> }
      //   ]
      // },
      {
        path: "login", children: [
          { path: "", element: <Login /> }
        ]
      }
    ]
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      { path: "*", element: <NotFound /> }
    ]
  }
];

const zemiMenu = [
  // {
  //   id: 1, title: "studio", link: "/studio/content/management", children: [
  //     { id: 10, title: "콘텐츠 관리", link: "/studio/content/management" },
  //     { id: 11, title: "콘텐츠 검수", link: "/studio/content/examination" },
  //     { id: 12, title: "Guide 관리", link: "/studio/guide/management" },
  //   ]
  // },
  {
    id: 2, title: "게시판 관리", link: "/board/notice", children: [
      { id: 20, title: "공지사항", link: "/board/notice" },
      { id: 21, title: "업데이트", link: "/board/update" },
      { id: 22, title: "이벤트", link: "/board/event" },
    ]
  },
  {
    id: 3, title: "커뮤니티 관리", link: "/community/free-board", children: [
      { id: 30, title: "자유게시판", link: "/community/free-board" },
      { id: 31, title: "팁 공유 게시판", link: "/community/tip-board" },
      { id: 32, title: "스크린샷 게시판", link: "/community/screenshot-board" },
      // { id: 33, title: "신고내역", link: "/community/report" },
    ]
  },
  // {
  //   id: 4, title: "고객센터 관리", link: "/customer/faq", children: [
  //     { id: 40, title: "FAQ", link: "/customer/faq" },
  //     { id: 41, title: "1:1 문의", link: "/customer/inquiry" },
  //   ]
  // },
  // {
  //   id: 5, title: "회원 관리", link: "/user/list", children: [
  //     { id: 50, title: "회원리스트", link: "/user/list" },
  //   ]
  // },
  // {
  //   id: 6, title: "관리자 설정", link: "/admin/account", children: [
  //     { id: 60, title: "관리자 계정관리", link: "/admin/account" },
  //   ]
  // },
];

const boardHeader = [
  {
    path: "notice",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성일" },
      { id: 4, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "공지" },
      { id: 2, title: "점검" },
      { id: 3, title: "상점" },
    ]
  },
  {
    path: "update",
    boardTitle: [],
    boardCategory: []
  },
  {
    path: "event",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "진행" },
      { id: 2, title: "예정" },
      { id: 3, title: "종료" },
    ]
  },
  {
    path: "free-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "최신순" },
      { id: 2, title: "인기순" },
      // { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "tip-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "최신순" },
      { id: 2, title: "인기순" },
      // { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "screenshow-board",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "최신순" },
      { id: 2, title: "인기순" },
      // { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "report",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "신고 원문" },
      { id: 3, title: "작성자" },
      { id: 4, title: "신고 횟수" },
      { id: 5, title: "처리상태" }
    ],
    boardCategory: []
  },
  {
    path: "faq",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "ZEMIVERSE" },
      { id: 2, title: "Studio" },
    ]
  },
  {
    path: "inquiry",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성자" },
      { id: 4, title: "작성일" },
      { id: 5, title: "처리상태" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "문의" },
      { id: 2, title: "버그 제보" },
      { id: 3, title: "신고" },
    ]
  },
  {
    path: "list",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "E-mail" },
      { id: 2, title: "소셜" },
      { id: 3, title: "닉네임" },
      { id: 4, title: "계정 연동" },
      { id: 5, title: "연락처" },
      { id: 6, title: "최종 접속일" },
      { id: 7, title: "접속 횟수" },
      { id: 8, title: "상태" },
    ],
    boardCategory: []
  },
  {
    path: "account",
    boardTitle: [
      { id: 0, title: "구분" },
      { id: 1, title: "ID" },
      { id: 2, title: "담당자명" },
      { id: 3, title: "최근접속일" },
      { id: 4, title: "관리" }
    ],
    boardCategory: []
  },
];

const CategoryList = [
  { id: 0, title: "제목" },
  { id: 1, title: "내용" },
  { id: 2, title: "제목 + 내용" },
];

const BoardTopTableData = [
  { id: 0, title: "총 회원 수", user: 1234 },
  { id: 1, title: "Facebook", user: 1234 },
  { id: 2, title: "Google", user: 1234 },
  { id: 3, title: "Apple", user: 1234 }
];

const useRouting = () => {
  const navigate = useNavigate();

  const goToMain = () => {
    navigate(`/`);
  }

  const goToPage = (path: string, params?: any) => {
    navigate(path, params);
  }

  const goToBack = () => {
    navigate(-1);
  }

  return {
    goToPage,
    goToBack,
    goToMain
  };
}

export { path, zemiMenu, boardHeader, CategoryList, BoardTopTableData, useRouting };