import './checkbox.scss';
import classNames from 'classnames';

export interface CheckBoxProps {
  name?: string,
  value: any,
  onClick: any,
  size?: string,
  label: any,
  checked?: boolean
}

/**
 * checkbox 컴포넌트
 * 
 * @param name checkbox를 구분할 id
 * @param value checkbox의 check여부
 * @param onClick checkbox를 클릭했을 때 동작할 메서드 
 * @param size checkbox의 크기 
 */
const CheckBox = ({name, value, onClick, size, label, checked}: CheckBoxProps) => {
  return (
    <div className="checkbox">
      <div className='checkbox__wrapper'>
        <input type="checkbox" id={name} value={value} onChange={onClick} checked={checked} />
        <label htmlFor={name} className={classNames(size)}></label>
      </div>
    </div>
  );
};

export default CheckBox;