import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ContentFooter, MainHeader, ConfirmModal } from '@components/index';

import { RootState, ModalState, modalOpenSetting, modalIsOk } from "@store/index";

import './mainLayout.scss';

const MainLayout = () => {

  const { t, i18n } = useTranslation();

  const modal:ModalState = useSelector((state: RootState) => state.modal);

  return (
    <div className={classNames(`App`, i18n.language)}>
      {/* Header Components가 들어가는 부분 */}
      <MainHeader /> 
        {/* Main Content */}
      <Outlet />
      <ContentFooter />
      {/* Modal */}
      {(modal.open &&( modal.type === "warning" || modal.type === "danger" || modal.type === "success")) && <ConfirmModal title={modal.title} description={modal.description} cancel="취소" type={modal.type} confirmButton={modal.confirmButton} confirmStyle={modal.confirmStyle} />}
    </div>
  )
};

export default MainLayout;