// import { LanguageBox } from "@components/header";
import classNames from "classnames";

import './contentFooter.scss';

type SnsButtonProps = {
  title: string,
  url: string
}

const SnsButton = ({ title, url }: SnsButtonProps) => {
  return (
    <li
      className={classNames("sns_button", title)}
      onClick={() => {
        window.open(url, '_blank')
      }}
    >{title}</li>
  );
}


const ContentFooter = () => {
  return (
    <div className="content_footer">        
      <p className="copyright">Copyright© 2023 NEXTOR. All rights reserved.</p>
    </div>
  )
}
export default ContentFooter;