export interface PageTopProps {
  title: string;
}

const PageTop = ({title} : PageTopProps) => {
  return(
    <div className="page_top">
      <p className="title">{title}</p>
    </div>
  );
}

export default PageTop;