import classNames from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import { RootState, ModalState, modalOpenSetting, modalTypeSetting } from "@store/index";

import { Hierarchy } from '@components/common/NormalButton/NormalButton';
import NormalButton from '@components/common/NormalButton/NormalButton';

import '../modal.scss';

type ConfirmModalType = "success" | "warning" | "danger";

interface ConfirmModalProps {
  type?: ConfirmModalType | string | undefined;
  title: string | undefined;
  description?: string;
  cancel: string;
  confirmButton: string | undefined;
  confirmStyle?: Hierarchy | string | undefined;
}

const ConfirmModal = ({type, title, description, cancel, confirmButton, confirmStyle} : ConfirmModalProps) => {

  const dispatch = useDispatch();
  const modal:ModalState = useSelector((state: RootState) => state.modal);

  const handleConfirmClick = () => {
    modal.onConfirm();
    dispatch(modalOpenSetting(!modal.open));
  };

  return(
    <div className="dim">
      <div className={classNames("modal confirm", type)}>
      <div className="icon_box">
          <div className="icon"></div>
        </div>

        <p className="title">{title}</p>
        <p className="description">{description}</p>

        <div className="button_box">
          <NormalButton 
            title={cancel}
            size="lg"
            buttonStyle="box"
            hierarchy="secondary_gray"
            onClick={() => {
              dispatch(modalOpenSetting(!modal.open));
            }}
          />
          <NormalButton 
            title={confirmButton}
            size="lg"
            buttonStyle="box"
            hierarchy={confirmStyle ? confirmStyle : "secondary_gray"}
            onClick={() => {
              handleConfirmClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;