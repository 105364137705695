import classNames from "classnames";
import { Link, Location } from "react-router-dom";
import { useDispatch } from "react-redux";
import './menuBox.scss';
import { currentCategorySetting } from "@store/index";

interface MenuBoxProps { 
  id: number;
  title: string;
  link: string;
  children?: any[];
  currentPath: Location;
  currentCategory: number;
}

const MenuBox = ({ id, title, link, children, currentPath, currentCategory }: MenuBoxProps) => {

  const dispatch = useDispatch();

  return (
    <div className={classNames("menu_box__wrapper", {"on": currentCategory === id})}>
      <div className="deps-01">
        <Link to={link} onClick={() => dispatch(currentCategorySetting(id * 10))}>
          <p>{title}</p>
        </Link>
      </div>
      <div className={classNames("deps-02")}>
        {children && children.map((d: any) => (
          <Link key={d.id} to={d.link} className={classNames({"on":currentPath.pathname === d.link})} onClick={() => dispatch(currentCategorySetting(d.id))}>
            <p>{d.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MenuBox;