const IS_LOADING = 'alert/IS_LOADING' as const;

type LoadingAction =
  | ReturnType<typeof loadingSetting>
;

export const loadingSetting = (diff: boolean) => ({
  type: IS_LOADING,
  payload: diff
});

export type LoadingState = {
  isLoading: boolean;
};

const initialState: LoadingState = {
  isLoading: false,
};

function alert(
  state: LoadingState = initialState,
  action: LoadingAction
): LoadingState {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default alert;