import classNames from "classnames";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import { useTranslation } from 'react-i18next';

import { BoardListProps } from "./BoardList";

import { RootState, PathState } from "@store/index";
import { dateToString } from '@shared/index';

import './boardList.scss';

const NoticeList = ({ data, detail, background, id }: BoardListProps) => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const path: PathState = useSelector((state: RootState) => state.path);

  const [newList, setNewLIst] = useState(false);

  const NewList = () => {
    var now = new Date();
    var yesterday = new Date(now.setDate(now.getDate() - 1));
    const date: Date = new Date(data.regdate);
    setNewLIst(yesterday.getTime() < date.getTime());
  }

  useEffect(() => {
    if (!detail) {
      NewList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
      onClick={() => {
        navigate(`/board/${path.nowPath}/${data._id}`, {state: {_id: data._id}});
      }}
      style={{ "background": background }}
    >
      <p className="id">{id}</p>
      <p className="category">
        <span className={data.category}>{data.category}</span>
      </p>
      <div className="title_box">
        <p className="title">
          {data.title}&nbsp;
        </p>
        {(newList && !detail) && <span className="new"></span>}
      </div>
      <p className="date">
        {dateToString(data.regdate, 'yyyy.MM.dd', i18n.language)}
      </p>
      {!detail && <p className="view">{(data.hit === null || data.hit === undefined) ? "0" : data.hit}</p>}
    </div>
  );
}

export default NoticeList;