import './studioManagement.scss';

const StudioManagement = () => {
  return (
    <div className="component">
      콘텐츠 관리 페이지입니다.
    </div>
  );
};

export default StudioManagement;