import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import classNames from "classnames";

import './categoryBox.scss';

export interface CategoryBoxProps {
  data: any,
  category: number,
  setCategory: Dispatch<SetStateAction<number>>
}

/**
 * Board Category Selector Box
 * @data 카테고리 목록
 * @category 현재 카테고리
 * @setCategory 카테고리 설정
 */
const CategoryBox = ({data, category, setCategory} : CategoryBoxProps) => {

  const CategoryList = (data: any, key: number) => {
    return(
      <li 
        className={classNames({on : category === data.data.id})}
        onClick={() => {
          setCategory(data.data.id);
          
          console.log(data);
          console.log(data.index);
          console.log(category);
        }}
      >{data.data.title}</li>
    );
  }

  return (
    <ul className="category_box">
      {data.map((a : any, idx: number) => (
        <CategoryList data={a} key={idx}/>
      ))}
    </ul>
  );
}

export default CategoryBox;