import { useState } from "react";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { BoardListProps } from "./BoardList";
import NormalButton from "@components/common/NormalButton/NormalButton";

import { RootState, PathState } from "@store/index";
import { dateToString } from '@shared/index';

import './boardList.scss';
import { useEffect } from "react";

const UpdateList = ({ data, detail, background, onClick }: BoardListProps) => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);  

  const [newList, setNewLIst] = useState(false);

  const NewList = () => {
    var now = new Date();
    var yesterday = new Date(now.setDate(now.getDate() - 1));
    const date: Date = new Date(data.regdate);
    setNewLIst(yesterday.getTime() < date.getTime());
  }

  useEffect(() => {
    NewList();
  }, []);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
      onClick={() => {
        navigate(`/board/${path.nowPath}/${data._id}`, {state: {_id: data._id}});
      }}
      style={{ "background": background }}
    >
      <img src={`${data.thumbnail}`} alt="img" />

      <div className="board_list__box">
        <div className="top">
          {path.nowPath === "event" && (<p className="category">
            <span className={data.status}>{data.status}</span>
          </p>)}
          <p className="title">
            {data.title}
          </p>
          {newList && <span className="new"></span>}
        </div>

        <div className="box">
          <p className="description">
            {data.summary}
          </p>
        </div>

        <div className="bottom">
          {path.nowPath === "update" ? (
            <>
              <p className="update_date">
                <span>업데이트</span>
                {dateToString(data.updatetime, 'yyyy.MM.dd', i18n.language)}
              </p>
              <p className="date">
                <span>작성일</span>
                {dateToString(data.regdate, 'yyyy.MM.dd', i18n.language)}
              </p>
              <p className="view">
                <span>조회수</span>
                {(data.hit === null || data.hit === undefined || data.hit === "") ? 0 : data.hit}
              </p>
            </>
          ) : (
            <>
              <p className="update_date">
                <span>응모자</span>
                {/* {(data.hit === null || data.hit === undefined || data.hit === "") ? 0 : data.hit} */}
                0
              </p>
              <p className="view">
                <span>조회수</span>
                {(data.hit === null || data.hit === undefined || data.hit === "") ? 0 : data.hit}
              </p>
              <p className="date">
                <span>이벤트 기간</span>
                {dateToString(data.starttime, 'yyyy.MM.dd hh:mm', i18n.language)} ~ {dateToString(data.endtime, 'yyyy.MM.dd hh:mm', i18n.language)}
              </p>
            </>
          )}
          
        </div>
      </div>

      <div className="buttons_box"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NormalButton
          title="수정"
          hierarchy="primary_line"
          size="xs"
          buttonStyle="box"
          onClick={() => {
            navigate(`/board/${path.nowPath}/write`, {state: {_id: data._id}});
          }}
        />
        <NormalButton
          title="삭제"
          hierarchy="secondary_gray"
          size="xs"
          buttonStyle="box"
          onClick={(e : React.MouseEvent<HTMLElement>) => {
            if (window.confirm(`정말로 삭제 하시겠습니까?`)) {
              onClick(data._id);
            } else {
            }
          }}
        />
      </div>
    </div>
  );
}

export default UpdateList;