import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import { BoardListProps } from "./BoardList";

import { RootState, PathState } from "@store/index";

import {ReactComponent as ViewIcon} from '@assets/images/board/view.svg';
import {ReactComponent as HeartIcon} from '@assets/images/board/heart.svg';

import './boardList.scss';
import { useEffect } from "react";

const ScreenshotList = ({ data, detail, background, id }: BoardListProps) => {

  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  useEffect(() => {
    console.log(`data`, data);
  }, []);

  return(
    <div
        className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
        onClick={() => {
          navigate(`/community/${path.nowPath}/${data._id}`, {state: {_id: data._id}});
        }}
        style={{ "background": background }}
      >
        <img src={data.file} alt="img" />
        <div className="title_box">
          <p className="title">
            {data.title}
          </p>
          <span className="new"></span>
        </div>
        {(data.userid?.nickname !== undefined || data.userid?.nickname !== null) && (<p className="user">{data.userid?.nickname}</p>)}
        <p className="like">{data.hart?.length === 0 ? 0 : data.hart?.length}</p>
        <p className="view">{data.hit}</p>
      </div>
  );
}

export default ScreenshotList;