import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import { BoardListProps } from "./BoardList";

import { RootState, PathState } from "@store/index";

import './boardList.scss';

const UserList = ({ data, detail, background, id }: BoardListProps) => {

  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
      onClick={() => {
        navigate(`/user/${path.nowPath}/deatil/${data.index}`);
      }}
      style={{ "background": background }}
    >
      <p className="id">{id}</p>
      <p className="email">{data.email}</p>
      <p className="social">{data.social}</p>
      <p className="user">{data.user}</p>
      <p className="connected">{data.connected}</p>
      <p className="phone">{data.phone}</p>
      <p className="date">{data.date}</p>
      <p className="connect">{data.connect}</p>
      <p className="state">
        <span className={classNames(data.state)}>{data.state}</span>
      </p>
    </div>
  );
}

export default UserList;