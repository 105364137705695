import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import Pagination from "react-js-pagination";
import classNames from "classnames";

import { CategoryBox, BoardList, PageTop, InputBox, SelectBox, NormalButton, Loading, NoList } from "@components/index";
import { RootState, PathState, LoadingState, loadingSetting, LOGINState } from "@store/index";
import useSelect from '@hooks/useSelect';
import { boardTitle } from "@shared/index";
import { boardHeader, CategoryList, BoardTopTableData } from "@shared/config/router/path";
import { REACT_APP_SERVER_URL } from '../../../shared/utils/config';

import { ReactComponent as SearchIcon } from '@assets/images/layer/search.svg';

const Board = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const path: PathState = useSelector((state: RootState) => state.path);
  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const login: LOGINState = useSelector((state: RootState) => state.login);

  const [nowHeader, setNowHeader] = useState<any[]>([]);
  const [nowCategory, setNowCategory] = useState<any[]>([]);

  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const selectData = useSelect(CategoryList[0]);
  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장

  const [currentList, setCurrentList] = useState([]); // 현제 페이지의 리스트
  const [page, setPage] = useState(1); // 현재 페이지
  const handlePageChange = (page: number) => { setPage(page) };
  const [listPerPage] = useState(12); // 페이지 당 리스트 개수
  const indexOfLastList = page * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [boardType, setBoardType] = useState("");
  const [pathType, setPathType] = useState("");

  const _nowPath = (data: any) => {
    if (data.path === path.nowPath) {
      return true;
    }
  }

  const _nowHeader = () => {
    var a: any = boardHeader.filter(_nowPath);
    if (a.length <= 0) {
      return false;
    } else {
      setNowHeader(a[0].boardTitle);
      setNowCategory(a[0].boardCategory);
    }
  }

  const DeleteApi = async (id: string) => {
    await axios
      .delete(`${REACT_APP_SERVER_URL}/board/${path.nowPath}/${id}`, {
        headers : {
          "Authorization" : sessionStorage.getItem(`accessToken`)
        }
      })
      .then(({ data }) => {
        console.log(data.response.data);
        setTimeout(() => {
          setTimeout(() => {
            alert('삭제되었습니다');
          }, 2000);
        }, 2000);
        fetchData();
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  };

  const getApiUrl = (category: number, searchValue?: string, status?: string) => {

    const searchFields: any = {
      0: "title",
      1: "content",
      2: "title_content"
    };
    const searchField = searchFields[selectData.value.id];

    let url = `${REACT_APP_SERVER_URL}/${boardType}${pathType}`;

    const queryParams = [];
    if (category !== 0) {
      if (path.nowPath === 'event') {
        queryParams.push(`status=${category === 1 ? "진행" : category === 2 ? "예정" : "종료"}`);
      } else if (path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board") {
        if (category === 1) {
          queryParams.push(`new=true`);
        } else if (category === 2) {
          queryParams.push(`hot=true`);
        } else if (category === 3) {
          queryParams.push(`comment=true`);
        } 
      } else {
        queryParams.push(`category=${category}`);
      }
    }
    if (searchValue) {
      queryParams.push(`${searchField}=${searchValue}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    return url;
  };

  const fetchApi = async (url: string) => {
    console.log('url >>>>>',url);
    dispatch(loadingSetting(true));
    try {
      const { data } = await axios.get(url, {
        headers : {
          "Authorization" : sessionStorage.getItem(`accessToken`)
        }
      });
      console.log('api data', data);
      console.log('api data response data', data.response.data);
      // console.log('api data data', data.data);
      console.log('api url', url);
      setListData(data.response.data);
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
    dispatch(loadingSetting(false));
  };

  const fetchData = async () => {
    const url = getApiUrl(category, searchValue);
    if (path.nowPath !== "main") {
      fetchApi(url);
    }
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    const isEnterKey = e.key === 'Enter';
    if (isEnterKey) {
      fetchData();
      setPage(1);
    }
  };

  const resetPage = () => {
    _nowHeader();
    setCategory(0);
    setSearchValue("");
    setPage(1);
  };

  useEffect(() => {
    let a = (["/board/notice", "/board/update", "/board/event"].includes(location.pathname) ? `board` : ["/community/free-board", "/community/tip-board", "/community/screenshot-board"].includes(location.pathname) ? `community` : ``);
    let b = ((location.pathname === "/board/notice") ? `/notice` : (location.pathname === "/board/update") ? `/update` : (location.pathname === "/board/event") ? `/event` : (location.pathname === "/community/free-board") ? `/forum` : (location.pathname === "/community/tip-board") ? `/tip` : (location.pathname === "/community/screenshot-board") ? `/screenshot` : (location.pathname === "list") ? `/list` : ``);
    setBoardType(a);
    setPathType(b);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (boardType === "" || pathType === "") {
    } else {
      resetPage();
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathType]);

  useEffect(() => {
    if (boardType === "" || pathType === "") {
    } else {
      setSearchValue("");
      fetchData();
      setPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (listData === null || listData === undefined) {
    } else {
      setCurrentList(listData.slice(indexOfFirstList, indexOfLastList));
    }
  }, [indexOfFirstList, indexOfLastList, page, listData]);

  return (
    <div className={classNames("component", path.nowPath)}>
      <div className="component__inner">
        <PageTop title={boardTitle(path.nowPath)} />
        <div className="board">
          <div className="board__top">
            {/* {path.nowPath === "list" && (
              <div className="table">
                {BoardTopTableData.map((data, idx) => {
                  return (
                    <BoardTopTableList data={data} key={idx} />
                  );
                })}
              </div>
            )} */}
            {(path.nowPath === "notice"
              || path.nowPath === "event"
              || path.nowPath === "free-board"
              || path.nowPath === "tip-board"
              || path.nowPath === "screenshot-board"
              || path.nowPath === "inquiry") && <CategoryBox data={nowCategory} category={category} setCategory={setCategory} />}
            <div className="right">
              {(path.nowPath !== "list")
                && <SelectBox
                  name="category"
                  data={CategoryList}
                  selectData={selectData}
                  classification
                />}
              <InputBox
                value={searchValue || ""}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="검색어를 입력해주세요."
                onKeyPress={handleOnKeyPress}
                icon
                type="text"
              />
              <SearchIcon />
            </div>
          </div>
          <div className="board__body">
            {currentList.length <= 0 ? (
              <NoList />
            ) : (
              <>
                {["notice", "free-board", "tip-board", "report", "inquiry", "list", "account"].includes(path.nowPath) &&
                  <div className="board__body__header">
                    {nowHeader.map((data, idx) => (
                      <p key={idx}>{data.title}</p>
                    ))}
                  </div>
                }
                {loading.isLoading ? (
                  <Loading />
                ) : currentList.length <= 0 || currentList === null || currentList === undefined ? (
                  <NoList />
                ) : (
                  currentList.map((data, idx) => {
                    return (
                      <BoardList data={data} key={idx} id={page > 1 ? ((page - 1) * listPerPage) + (idx + 1) : idx + 1} onClick={DeleteApi} />
                    );
                  })
                )}
              </>
            )}
          </div>
          <div className="board__bottom">
            {currentList.length <= 0 || currentList === null || currentList === undefined ? (
              null
            ) : (
              <>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={listPerPage}
                  totalItemsCount={listData === null || listData === undefined ? 0 : listData.length}
                  pageRangeDisplayed={10}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                  innerClass="board__pagination"
                  activeClass="selected"
                  itemClassFirst="normal_button box sm secondary_gray"
                  itemClassPrev="normal_button box sm secondary_gray"
                  itemClassNext="normal_button box sm secondary_gray"
                  itemClassLast="normal_button box sm secondary_gray"
                />
                <div className="mobile_pagination">
                  <NormalButton
                    title="<"
                    size="sm"
                    hierarchy="secondary_gray"
                    buttonStyle="box"
                    onClick={() => {
                      if (page > 1) {
                        handlePageChange(page - 1);
                      }
                    }}
                  />
                  {currentList === null || currentList === undefined || listData === null || listData === undefined ? (
                    null
                  ) : (
                    <p className="page">Page {page} of {(listData.length / listPerPage) < 1 ? "1" : Math.ceil(listData.length / listPerPage)}</p>
                  )}

                  <NormalButton
                    title=">"
                    size="sm"
                    hierarchy="secondary_gray"
                    buttonStyle="box"
                    onClick={() => {
                      if (page < Math.ceil(listData.length / listPerPage)) {
                        handlePageChange(page + 1);
                      }
                    }}
                  />
                </div>
              </>
            )}
            {(path.nowPath !== "list" && path.nowPath !== "screenshot-board" && login.login) && (
              <NormalButton
                title={path.nowPath === "notice" ? "글쓰기" : "등록"}
                size="md"
                hierarchy="primary"
                icon="false"
                buttonStyle="box"
                onClick={() => {
                  navigate(`/${boardType}/${path.nowPath}/write`);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board; 