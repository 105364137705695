import axios from 'axios';
import { InputBox, CheckBox } from '@components/index';
import NormalButton from '@components/common/NormalButton/NormalButton';
import useCheckbox from '@hooks/useCheckbox';
import useInput from '@hooks/useInput';
import { boardTitle, REACT_APP_SERVER_URL } from '@shared/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './login.scss';
import { loginSetting } from '@store/login';

const Login = () => {

  const id = useInput('');
  const password = useInput('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveId = useCheckbox(false);

  const _login = async () => {
    await axios
      .post(`${REACT_APP_SERVER_URL}/admin/oauth`, {
        userid : id.value,
        password : password.value
      }, {
        // headers : {
        //   "Content-Type" : "application/x-www-form-urlencoded"
        // }
      })
      .then(({ data }) => {
        // console.log(data.response.data);
        // console.log('로그인 시도 >>>', data);
        sessionStorage.setItem(`accessToken`, data.response.data.access_token);
        sessionStorage.setItem(`refreshToken`, data.response.data.refreshToken);
        _adminData(data.response.data.access_token);
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  }; 

  const _adminData = async (accessToken : string) => {
    await axios
      .get(`${REACT_APP_SERVER_URL}/admin/members/me`, {
        headers : {
          "Authorization": accessToken
        }
      })
      .then(({ data }) => {
        console.log('로그인한 계정 정보 >>>',data.response.data);
        dispatch(loginSetting({login: true, 
          // level: data.response.data.level, name: data.response.data.name, 
          nickname: data.response.data.nickname, userid: data.response.data.userid, _id: data.response.data._id}));
        navigate('/board/notice');
      })
      .catch((e) => {
        console.error('admin e', e);
        // alert("개발팀에 문의해주세요.");
      });
  }

  return (
    <div className="content login">
      <div className="login-member">
      <div className="login-member__base">
        <div className='login-member__top'>
          <div className="login-member__header">
            <p className="login-member__title">ZEMIWORLD</p>
            <p className="login-member__description">Administrator Login</p>
          </div>
          <div className='login-member__form'>
            <InputBox name="id" value={id.value} onChange={id.onChange} placeholder="ID" type="text"/>
            <InputBox name="password" value={password.value} onChange={password.onChange} placeholder="PW" type='password'/>
          </div>
          {/* <CheckBox name="save_id" value={ saveId.value } onClick={saveId.onClick} size="sm" label="아이디 저장" /> */}
        </div>
        <NormalButton 
          title='LOGIN'
          buttonStyle='box'
          hierarchy="primary"
          size='md'
          width={"100%"}
          onClick={() => {
            _login();
          }}
        />
      </div>
    </div>
    </div>
  );
};

export default Login;