const PATH_SETTING = 'path/PATH_SETTING' as const;

type GNBAction =
  | ReturnType<typeof pathSetting>
;

export const pathSetting = (diff: string) => ({
  type: PATH_SETTING,
  payload: diff
});

export type PathState = {
  nowPath: string;
};

const initialState: PathState = {
  nowPath: "main",
};

function path(
  state: PathState = initialState,
  action: GNBAction
): PathState {
  switch (action.type) {
    case PATH_SETTING:
      return { ...state, nowPath: action.payload };
    default:
      return state;
  }
}

export default path;