import './notFound.scss';
import { useRouting } from '@shared/config/router/path';
import NormalButton from '@components/common/NormalButton/NormalButton';
const NotFound = () => {
  const { goToBack } = useRouting();
  return (
    <div className="content not_found">
      <div className='not_found--image'></div>
      <div className="not_found__content">
        <div className="not_found__title">원하는 페이지를 찾을 수 없습니다.</div>
        <div className="not_found__description">
          <p>찾으려는 페이지의 주소가 잘못 입력되었거나, 주소의 변경 혹은 삭제로 인해 사용할 수 없습니다. <br />
            입력하신 페이지의 주소가 정확한지 확인해주세요.</p>
        </div>
        <div className="not_found__footer">
          <NormalButton
            title="이전 페이지로 가기"
            hierarchy="primary_line"
            onClick={goToBack}
            buttonStyle="round"
            size="xl"
            width="166px"
            height="48px"
            // disable={true}
            buttonClassName="to_prev"
          />
          <NormalButton
            title="메인으로 가기"
            hierarchy="primary"
            // onClick={goToZemiverse}
            buttonStyle="round"
            size="xl"
            // disable={true}
            width="132px"
            height="48px"
            buttonClassName="to_main"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;