import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { BoardListProps } from "./BoardList";
import { dateToString } from '@shared/index';

import { RootState, PathState } from "@store/index";

import './boardList.scss';

const ReportList = ({ data, detail, background, id }: BoardListProps) => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const path: PathState = useSelector((state: RootState) => state.path);

  return (
    <div
        className={classNames("board_list", path.nowPath, { fixed: data.fixed, detail: detail })}
        onClick={() => {
          if (path.nowPath === "report") {
            navigate(`/community/${path.nowPath}/detail/${data._id}`);
          } else {
            navigate(`/customer/${path.nowPath}/detail/${data._id}`);
          }
        }}
        style={{ "background": background }}
      >
        <p className="id">{id}</p>
        <p className="category">
          <span className={data.category}>{data.category}</span>
        </p>
        <div className="title_box">
          <p className="title">{data.title}</p>
        </div>
        <p className="user">{data.userid.nickname}</p>

        {path.nowPath === "report" && <p className="report">{data.count}</p>}
        {path.nowPath === "inquiry" && <p className="date">{dateToString(data.regdate, 'yyyy.MM.dd', i18n.language)}</p>}

        <p className="state">{data.status}</p>
      </div>
  );
}

export default ReportList;