const GNB_SETTING = 'gnb/GNB_SETTING' as const;
const CURRENT_CATEGORY_SETTINGS = "gnb/CURRENT_CATEGORY_SETTINGS" as const;

type GNBAction =
  | ReturnType<typeof gnbSetting>
  | ReturnType<typeof currentCategorySetting>
;

export const gnbSetting = (diff: boolean) => ({
  type: GNB_SETTING,
  payload: diff
});

export const currentCategorySetting = (diff: number) => ({
  type: CURRENT_CATEGORY_SETTINGS,
  payload: diff
})

export type GNBState = {
  open: boolean;
  currentCategory: number;
};

const initialState: GNBState = {
  open: false,
  currentCategory: 0
};

function gnb(
  state: GNBState = initialState,
  action: GNBAction
): GNBState {
  switch (action.type) {
    case GNB_SETTING:
      return { ...state, open: action.payload };
    case CURRENT_CATEGORY_SETTINGS: 
      let data = parseInt((action.payload / 10) as unknown as string);
      return {...state, currentCategory: data}
    default:
      return state;
  }
}

export default gnb;